/*!
 * Copyright The Linux Foundation and each contributor to LFX.
 * SPDX-License-Identifier: MIT
 */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.min.css';
/*@import 'primeicons/primeicons.css';*/

/* fontawesome-pro */
/*@import '@fortawesome/fontawesome-pro/css/all.css';*/
@import '@fortawesome/fontawesome-pro/css/fontawesome.min.css';
@import '@fortawesome/fontawesome-pro/css/regular.min.css';

/* You can add global styles to this file, and also import other style files */
@import './app/shared/styles/container';
@import './app/shared/styles/buttons';
@import './app/shared/styles/forms';
@import './app/shared/styles/typography';

html,
body {
  @apply bg-white h-full;
}
body {
  @apply m-0 font-roboto text-gray-dark;
  font-family: 'Open Sans', 'Roboto Slab', sans-serif;
}

.status-icon {
  @apply mx-1 relative inline-block w-4 h-4 -mb-[3px];
}

@layer utilities {
  .debug-resp {
    @apply bg-red-50 md:bg-blue-50 lg:bg-green-50 xl:bg-gray-300 2xl:bg-amber-200;
  }
}
.p-overlaypanel-content {
  padding: 0 !important;
}

.cdk-overlay-backdrop,
.cdk-overlay-container {
  z-index: 17 !important;
}

.am5-modal {
  z-index: 10 !important;
  .am5-modal-curtain {
    @apply z-[8];
  }
  .am5-modal-wrapper {
    @apply z-[9];
  }
}

// tooltip
.p-tooltip {
  .p-tooltip-text {
    @apply rounded-md bg-gray-dark py-1 px-4 font-sans text-xs text-white w-fit;
  }
}
