// Style definitions for typography

body {
  @apply font-sans text-sm text-text leading-[16.8px];
}

h1 {
  @apply text-2xl font-bold leading-h1 font-roboto mb-1;
}

h2 {
  @apply text-lg font-semibold leading-h2 font-roboto mb-1;
}

h3 {
  @apply text-sm font-semibold leading-h3 font-sans mb-1;
}

h4 {
  @apply text-xs font-bold leading-h4 font-sans mb-1;
}

h5 {
  @apply text-[10px] font-bold leading-h5 font-sans mb-1;
}

a {
  @apply font-sans text-sm text-primary;
}

// defined as Subtext in Figma
small {
  @apply text-11 text-text leading-[13.2px] font-sans;
}

small a {
  @apply text-11 leading-[13.2px];
}

label {
  @apply font-sans text-sm text-text leading-4 font-sans font-light;
}

.data-pt {
  @apply text-[31px] leading-[37.2px] text-text font-sans font-bold;
}

.data-pt-1 {
  @apply text-2xl leading-h1 font-sans font-bold;
}

.data-pt-2 {
  @apply text-lg leading-h2 font-sans font-bold;
}

.data-pt-3 {
  @apply text-sm leading-h3 font-sans font-bold;
}
