/*!
 * Copyright The Linux Foundation and each contributor to LFX.
 * SPDX-License-Identifier: MIT
 */

// These are simple boxes with border used for housing contents or data
.content-box {
  @apply bg-white rounded-lg relative px-8 py-6 relative;
  &.no-padding {
    @apply px-0 py-0;
  }
}

.download-wrap {
  @apply absolute top-6 right-8 z-[15];
}

article {
  @apply min-h-screen relative w-full max-w-screen-2xl;
}

.space-default {
  @apply mb-8;
}

.chart-comment-icon {
  @apply h-[24px] w-[24px] flex justify-center items-center;
}

.data-incomplete-bg {
  background-color: #fcede7 !important;
}

.cards {
  @apply border rounded-md w-[370px] flex flex-col;
}

.card-bg-color {
  @apply bg-gray-50;
}

.lfx-card-header-description {
  @apply text-sm text-gray-500;
}
